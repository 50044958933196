import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2'

const config = require('../config.json')

export default function Login({ ipAddress, onLogin }) {
    
    const [lid, setlid] = useState('')
    const [pass, setpass] = useState('')
    const history = useHistory();
    const handleLogin = async (event) => {
        event.preventDefault()
        login();
    }

    const login = async () => {
        let  ip = ipAddress
        let data = JSON.stringify({
            "lid": lid,
            "password": pass,
            "ip": ip
        });
        let axiosConfig = {
            method: 'post',
            url: `${config.baseUrl}api2`,
            headers: {
                'ulid': lid,
                'ip': ip,
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(axiosConfig)
        let response = await axios.request(axiosConfig)
        // console.log(response)
        response = response.data
        if (response.status) {
            //console.log(response.data)
            let loginData = response.data
            loginData.address = response.data.address
            await setLoginData(loginData)
            onLogin(response.data)
            
            history.push('/dash');
            
        }
        else if (response.code === 30) {
            Swal.fire({
                icon: 'info',
                title: 'Meros Media',
                text: response?.message
            }).then(() => {
                history.push('/register');
            })
        }
      
    };
    async function setLoginData(loginData) {
        console.log(loginData);
        localStorage.setItem('loginData', JSON.stringify(loginData));
        console.log(JSON.parse(sessionStorage.getItem('loginData')))
    }
    return (
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y" >
                <div className="authentication-inner py-4">
                    {/* Forgot Password */}
                    <div className="card">
                        <div className="card-body">
                            {/* Logo */}
                            <div className="app-brand justify-content-center">
                                <Link to="/" className="app-brand-link gap-2">
                                    <span className="app-brand-logo demo">
                                        <img src="assets/meros_black.png" style={{ height: '80px', width: 'auto' }} alt='ficon' />
                                    </span>
                                    
                                </Link>
                            </div>
                            {/* /Logo */}
                            <h6 className="mb-2 text-warning text-center">Welcome to Meros Media</h6>
                            <p className="mb-4"></p>
                            <form id="formAuthentication" className="mb-3" onSubmit={handleLogin}>
                            <div className="mb-3">
                                    <label htmlFor="1email" className="form-label">User ID</label>
                                    <input type="text" className="form-control" id="lid" name="lid" placeholder="Enter User ID" value={lid} onChange={(e) => setlid(e.target.value)} />
                                </div>
                                 <div className="mb-3">
                                    <label htmlFor="e1mail" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="pass" name="pass" placeholder="Enter your name" value={pass} onChange={(e) => setpass(e.target.value)} />
                                </div>
                                <button className="btn btn-info d-grid w-100" >Login</button>
                            </form>
                            <div className="text-center">
                                {/* registeration button */}
                                <Link to="/register" className="d-flex align-items-center justify-content-center text-secondary">
                                    Click to Register
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from 'react'
import MM from '../images/meros_white.png'
import './ReactLoader.css'

export default function ReactLoader() {
  return (
    <div style={{margin:'auto'}}>
        <img src={MM} alt="Page loading" width={100} height={100} className='pulse-animation' ></img>
    </div>
  )
}

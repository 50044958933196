import React, { useCallback, useContext, useEffect,useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import axios from 'axios';
import { LoadingContext } from '../context/LoadingContext';
import ReactLoader from '../components/ReactLoader';
const config = require('../config.json')


export default function Downline({ ipAddress, loginData }) {
  const [account, setAccount] = useState(loginData?.address);
  const [downline,setDownline] = useState({});
  const [downlineTable,setDownlineTable] = useState({});
  const [loading, setLoading] = useContext(LoadingContext)
  const [limit, setLimit] = useState(0)
  
  const handleDownline = useCallback(() => {
    setLoading(true)
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api2/downline`,
      headers: { 
        'address': account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)
    .then((response) => {
      setDownline(response.data)
      // setDownline(config.downline)
      setDownlineTable(response.data.downList)
      // console.log(response.data); 
      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
    });    
  },[account, ipAddress, loginData.auth, loginData.token, loginData.ulid, setLoading])
  
  useEffect(() => {
    handleDownline()
  },[handleDownline])

  return (
    <>
        <div className="layout-container">
          <Menu />
          <div className="layout-page">
            <Header />
            {loading ? <><ReactLoader/></> :
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y pt-2">
                <div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                        <div className="card">
                            <div className="card-body dashinc">
                                <div className="d-flex justify-content-between">
                                    <div className="card-info">
                                        <p className="card-text m-0 text-info text-sm">Total Registration</p>
                                        <div className="d-flex align-items-end mb-2">
                                            <small className="text-white ">{downline.total_downline}</small>
                                        </div>
                                        
                                    </div>
                                    <div className="card-icon">
                                        <span className="badge bg-label-info rounded p-2">
                                            <i className="bx bx-user bx-sm" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                        <div className="card">
                            <div className="card-body dashinc">
                                <div className="d-flex justify-content-between">
                                    <div className="card-info">
                                        <p className="card-text m-0 text-info text-sm">Active Members</p>
                                        <div className="d-flex align-items-end mb-2">
                                            <small className="text-white ">{downline.Active_downline}</small>
                                        </div>
                                        
                                    </div>
                                    <div className="card-icon">
                                        <span className="badge bg-label-info rounded p-2">
                                            <i className="bx bx-user bx-sm" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                        <div className="card">
                            <div className="card-body dashinc">
                                <div className="d-flex justify-content-between">
                                    <div className="card-info">
                                        <p className="card-text m-0 text-info text-sm">In-Active Members</p>
                                        <div className="d-flex align-items-end mb-2">
                                            <small className="text-white ">{downline.Inactive_downline}</small>
                                        </div>
                                        
                                    </div>
                                    <div className="card-icon">
                                        <span className="badge bg-label-info rounded p-2">
                                            <i className="bx bx-user bx-sm" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  
                  <div className='row'>
                  {
                              downline?.downList?.map((list, index)=>{
                                let stake = parseFloat(list?.invest).toFixed(3);
                                return ( 
                    <div className="col-md-4  mb-1" key={index+1}>
                      <div className="card h-100">
                        <div className="card-header align-items-center" style={{padding :"3% 5% 3% 5%"}}>
                          <div className="col-md-12">
                            <div className='row d-flex justify-content-between'>
                              <div className='col-6 text-left'>
                                <span className="text-white text-sm">ID - {list?.uslid}</span>
                              </div>
                              <div className='col-6' style={{textAlignLast:"end"}}>
                                <span className={`${(list.status === 'ACTIVE')?'text-info text-sm':'text-danger text-sm'}`} style={{fontSize:"14px"}}>{list?.status}</span>
                              </div>
                            </div>
                            <div className='row d-flex justify-content-between'>
                              <div className='col-6 text-left'>
                                  <small className="text-sm">Sponsor : {list?.splid}</small>
                              </div>
                              <div className='col-6' style={{textAlignLast:"end"}}>
                                <small className="text-sm">DOJ : {list?.adate}</small>
                              </div>
                            </div>
                            <div className='row d-flex justify-content-between'>
                              <div className='col-6 text-left'>
                                  <small className="text-sm">Level : </small>
                              </div>
                              <div className='col-6' style={{textAlignLast:"end"}}>
                                <small className="text-sm">{list?.level}</small>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    )
                    })
                  }
                  </div>
                  
                </div>
              </div>
              <Footer />
            </div>}
          </div>
        </div>
        
    </>
  );
}
import React, { useCallback, useContext, useEffect,useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import axios from 'axios';
import { LoadingContext } from '../context/LoadingContext';
import ReactLoader from '../components/ReactLoader';
const config = require('../config.json')

export default function Business({ipAddress, loginData}) {
    const [account, setAccount] = useState(loginData?.address);
  const [business,setBusiness] = useState({});
  const [loading, setLoading] = useContext(LoadingContext)
  const [businessTable,setBusinessTable] = useState({});
  
  const handleBusiness = useCallback(() => {
    setLoading(true)
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api2/income`,
      headers: { 
        'address': account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
        setBusiness(response.data)
        console.log(response.data)
      setBusinessTable(response.data.info)
    //   console.log(response.data); 
      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
    });
  },[account, ipAddress, loginData.auth, loginData.token, loginData.ulid, setLoading])

  useEffect(() => {
    
    handleBusiness()
  },[handleBusiness])
    return (
        <>
                <div className="layout-container">
                    <Menu />
                    <div className="layout-page">
                        <Header />
                        {loading ? <><ReactLoader/></> :
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y pt-2">
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                        <div className="card-header align-items-center ">
                                            <div className="card-title mb-0">
                                            <h6 className="m-0 me-2 text-center text-warning">Income List</h6>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" >
                                            <div className="card-body dashinc" >
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-white text-sm">Total Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white ">$ {business?.main?.total}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-white text-sm">Wallet Balance </p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white "> $ {business?.main?.rbalance}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    { (business?.main?.mentor)?
                                    <>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-white text-sm">Level Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white "> $ {business?.main?.level}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" >
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-white  text-sm">Passive Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white ">$ {business?.main?.passive}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" >
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text text-white m-0 text-sm">Sponsor Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white ">$ {business?.main?.sponinc}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </> : <></> }
                                    { (business?.main?.worker)? 
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" >
                                            <div className="card-body dashinc" >
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-white text-sm">Salary Total</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white ">$ 0.00</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :<></>}
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                    <div class="card">
                                        <p class="card-header">Income Information</p>
                                        <div class="card-datatable table-responsive text-center">
                                            <table class="datatable table border-top table-xs table-hover table-bordered table-striped text-center" style={{fontSize : "10px"}}>
                                                <thead >
                                                    <tr>
                                                        <th style={{fontSize:"11px"}}>Type of Income</th>
                                                        <th style={{fontSize:"11px"}}>Level</th>
                                                        <th style={{fontSize:"11px"}}>Refrence</th>
                                                        <th style={{fontSize:"11px"}}>On Total</th>
                                                        <th style={{fontSize:"11px"}}>Per</th>
                                                        <th style={{fontSize:"11px"}} >Amount</th>
                                                        <th style={{fontSize:"11px"}}>Date</th>
                                                        <th style={{fontSize:"11px"}}>Inforamtion</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                    business?.data?.map((list, index)=>{
                                                        
                                                        return ( 
                                                                <>
                                                                    <tr id={index}>
                                                                        <td>{list.type}</td>
                                                                        <td>{list.level}</td>
                                                                        <td>{list.refrence}</td>
                                                                        <td>{list.onTotal}</td>
                                                                        <td>{list.asPer}</td>
                                                                        <td>{list.netAmount}</td>
                                                                        <td>{list.dt}</td>
                                                                        <td>{list.information}</td>
                                                                    </tr>
                                                                </>      
                                                        )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    }
                    </div>
                </div>
        </>
    );
}

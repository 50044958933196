import React, { useCallback, useContext, useEffect,useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import axios from 'axios';
import { LoadingContext } from '../context/LoadingContext';
import ReactLoader from '../components/ReactLoader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const config = require('../config.json')

export default function Task({ipAddress, loginData}) {
    const [account, setAccount] = useContext(loginData?.address);
  const [business, setBusiness] = useState({});
  const [loading, setLoading] = useContext(LoadingContext)
  const [isset, setIsset] = useState(0)
  const handleBusiness = useCallback(() => {
    setLoading(true)
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api2/task`,
      headers: { 
        'address': account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
        setIsset(response.data?.task?.sttoday)  
        setBusiness(response.data)
        console.log(response.data)
      
    //   console.log(response.data); 
      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
    });
  },[account, ipAddress, loginData.auth, loginData.token, loginData.ulid, setLoading])

  useEffect(() => {
    
    handleBusiness()
  },[handleBusiness])
    return (
        <>
                <div className="layout-container">
                    <Menu />
                    <div className="layout-page">
                        <Header />
                        {loading ? <><ReactLoader/></> :
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y pt-2">
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                        <div className="card-header align-items-center ">
                                            <div className="card-title mb-0">
                                                <h6 className="m-0 me-2 text-center text-warning">Task Info</h6>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                        <div className="card-header align-items-center ">
                                            <div className="card-title mb-0">
                                                <p className="m-0 me-2 text-center text-warning">Todays Task {business?.task?.today}</p>
                                                
                                            </div>
                                            <div className="card-title mb-0 text-center mt-3">
                                                
                                                {(isset === 0) ? <Link to="/work" className="btn btn-sm btn-warning">Start</Link> : <></>}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                    <div class="card">
                                        <p class="card-header">Task  Information</p>
                                        <div class="card-datatable table-responsive text-center">
                                            <table class="datatable table border-top table-sm table-hover table-bordered table-striped text-center" style={{fontSize : "10px"}}>
                                                <thead >
                                                    <tr>
                                                        <th style={{fontSize:"11px"}}>Task Date</th>
                                                        <th style={{fontSize:"11px"}}>Total Send</th>
                                                        <th style={{fontSize:"11px"}}>Status</th>
                                                        <th style={{fontSize:"11px"}}>Dated</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        
                                                    business?.task?.all?.map((list, index)=>{
                                                        console.log(list);
                                                        return ( 
                                                                <>
                                                                    <tr id={index}>
                                                                        <td>{list.dt}</td>
                                                                        <td>{list.total_send}</td>
                                                                        <td>{list.status}</td>
                                                                        <td>{list.cdt}</td>
                                                                    </tr>
                                                                </>      
                                                        )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    }
                    </div>
                </div>
        </>
    );
}

import React, { useCallback, useContext, useEffect, useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import { LoadingContext } from '../context/LoadingContext';
import ReactLoader from '../components/ReactLoader';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import axios from 'axios';
import Swal from 'sweetalert2'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const config = require('../config.json')

export default function Dash({ipAddress, loginData}) {
  const [account] = useState(loginData?.address)
  const [loading, setLoading] = useContext(LoadingContext)
  const [referall, setReferall] = useState("")
  const [dash, setDash] = useState({})
  const history = useHistory();

  const handleDash = useCallback(async () => {
        setLoading(true)
    
        let data = JSON.stringify({
            "address": (loginData.address)?loginData.address:account,
            "ip": ipAddress,
            "ulid": loginData.ulid
        });
        let axiosConfig = {
            method: 'post',
            url: `${config.baseUrl}api2/desk`,
            headers: {
                'address': (loginData.address)?loginData.address:account, 
                'ip': ipAddress, 
                'ulid': loginData.ulid, 
                'auth': loginData.auth, 
                'token': loginData.token, 
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(axiosConfig)
        let response = await axios.request(axiosConfig)
        console.log(response)
        response = response.data
        setLoading(false)
        if (response.status) {
            console.log(response.data)
            setDash(response.info)
        }
        else if (response.code === 30) {
            Swal.fire({
                icon: 'info',
                title: 'Meros Media',
                text: response?.message
            }).then(() => {
                history.push('/register');
            })
        }
  }, [account, history, ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid, setLoading]);

  useEffect(()=>{
    handleDash()
  },[handleDash])
  

    return (
        <>
                <div className="layout-container">
                    <Menu />
                    <div className="layout-page">
                        <Header />
                        {loading ? <><ReactLoader/></> :
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y pt-2">
                                <div className="row">
                                    <div className="col-md-12  mb-2">
                                        <div className="card h-100">
                                            <div className="card-header align-items-center" style={{padding :"4% 5% 5% 5%"}}>
                                                <div className="col-md-12">
                                                    <div className='row d-flex justify-content-between'>
                                                        <div className='col-12 text-center'>
                                                                <p className="card-text m-0  text-md">Welcome</p>
                                                                <p className="card-text m-0 text-white text-sm">Dear {dash?.sponsor_id}</p>
                                                                { (dash?.mentor)?
                                                                <CopyToClipboard text={dash?.copyLink}>
                                                                    <button className="btn btn-warning btn-sm text-sm me-3 mt-2">Copy Referral Code</button>
                                                                </CopyToClipboard>
                                                                    :<></>}
                                                                <Link to="/buynow" className="btn btn-warning btn-sm text-sm me-sm-3 mt-2">Buy Package</Link>
                                                        </div>
                                                        
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" >
                                            <div className="card-body dashinc" >
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">Total Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white ">$ {dash?.total}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-white text-sm">Wallet Balance </p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white "> $ {dash?.rbalance}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    { (dash?.mentor)?
                                    <>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">Level Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white "> $ {dash?.level}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" onClick={()=>history.push('/perform')}>
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">Passive Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white ">$ {dash?.passive}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" >
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">Sponsor Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white ">$ {dash?.sponinc}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </> : <></> }
                                    { (dash?.worker)? 
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" >
                                            <div className="card-body dashinc" >
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">Salery Total</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white ">$ 0.00</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :<></>}
                                </div>
                            </div>
                            <Footer />
                        </div>
                        }
                    </div>
                </div>

        </>
    );
}

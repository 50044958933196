import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios';

import Swal from 'sweetalert2'
const config = require('../config.json')
export default function Leverage({ ipAddress, loginData }) {
    const [account, setAccount] = useState(loginData?.address);
    const [wallet, setWallet] = useState('')
    const [rebuy, setRebuy] = useState(0)
    const [bal, setBal] = useState(0)
    const sendToken = async (p) => {
        if(rebuy > 0 )
        {
                
        }
        else
        {
            Swal.fire({
                title : "Meros Media",
                icon : 'info',
                text : "Enter Valid Amount "
            })
        }
    }
    const handleLeverage = ( time, lkdamt, thash, pid) => {
           console.log(rebuy)
        let data = JSON.stringify({
            "address": account,
            "ip": ipAddress,
            "ulid": loginData.ulid,
            "usd": lkdamt,
            "thash" : thash,
            "packId" : pid,
            "tranTime"  : time
        });
        Swal.fire('Meros Media', "Please wait for geting Information", 'info')
        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}/api2/buy`,
            headers: {
                'address': account,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data);
                let  res = response.data;
                    Swal.fire({
                        title : "Meros Media",
                        icon : 'info',
                        text : res?.message
                    })
                    handleWallet()
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleWallet = useCallback(() => {

        let data = JSON.stringify({
            "address": account,
            "ip": ipAddress,
            "ulid": loginData.ulid
        });

        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}api2/package`,
            headers: {
                'address': account,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                setWallet(response.data)
                
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [account, ipAddress, loginData.auth, loginData.token, loginData.ulid])
    useEffect(()=>{
    handleWallet()
      },[handleWallet])

    const handleValues=async (e, p)=>{
        console.log(e);
        console.log(bal)
        if(bal >= e && bal > 0)
        {
            setRebuy(e)
            await sendToken(p)
        }
        else
        {
            Swal.fire("Meros Media", "Please Check USDT Bep-20 Balance. Your Balance :"+bal, "info");
        }
    }
    return (
        <>
            <div className="layout-container">
                <Menu />
                <div className="layout-page">
                    <Header />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y pt-2">
                            <div>
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                            <div className="card-header align-items-center ">
                                                <div className="card-title mb-0">
                                                    <h6 className="m-0 me-2 text-center text-warning mb-2">Package Inforamtion</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='row'>
                                    {wallet?.packages?.map((list, i)=> {
                                        return (
                                            <>
                                                <div className="col-md-6  mb-2" id={i+1}>
                                                    <div className="card ">
                                                        <div className="card-header align-items-center ">
                                                            <div className="card-title mb-0">
                                                                <h5 className="m-0 me-2 text-center text-warning mb-2">{list.package_name}</h5>
                                                                <h6 className="m-0 me-2 text-center text-warning mb-2">{list.pamt}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="card-body align-items-center p-3">
                                                            <div className='text-center mt-0'>
                                                                <button className='btn  btn-warning btn-sm' onClick={() => handleValues(list.net_amount, list.id)}>Buy {list.package_name}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div> */}
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div class="card">
                                            <p class="card-header">Package Buy History</p>
                                            <div class="card-datatable table-responsive text-center">
                                                <table class="datatable table border-top table-sm text-center table-hover table-bordered table-striped" style={{fontSize : "10px"}}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{fontSize:"11px"}}>#</th>
                                                            <th style={{fontSize:"11px"}}>Package</th>
                                                            <th style={{fontSize:"11px"}}>Amount</th>
                                                            <th style={{fontSize:"11px"}}>Date</th>
                                                            <th style={{fontSize:"11px"}}>transaction</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                        wallet?.invest?.map((list, index)=>{
                                                            let hr = `https://bscscan.com/tx/${list.tran_hash}`;
                                                            return ( 
                                                                    <>
                                                                        <tr id={index}>
                                                                            <td>{index+1}</td>
                                                                            <td>{list.pname}</td>
                                                                            <td>{list.inv}</td>
                                                                            <td>{list.dt}</td>
                                                                            <td><a href={'https://bscscan.com/tx/'+list.tran_hash} target="_blank" className='text-warning' rel="noreferrer">View</a></td>
                                                                            
                                                                        </tr>
                                                                    </>      
                                                            )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}